import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./solution.module.scss"
import Video from "../components/video"

const Solution = ({ data }) => {
  const {
    airtable: { data: solution },
  } = data
  return (
    <Layout
      pageTitle={solution.Detail_Title}
      solutionName={solution.Name}
      dark
      dataSheetLink={solution.Data_Sheet}
    >
      <SEO title={`${solution.Detail_Title}`} />
      <Container fluid className={`pt-4 ${styles.solutionDetailContainer}`}>
        <Row className={`d-flex flex-wrap ${styles.solutionDetailRow}`}>
          <Col className={`${styles.solutionDetailCol}`}>
            {solution.Video_Link ? (
              <Video
                className={`w-100 ${styles.solutionVideo}`}
                videoSrcURL={solution.Video_Link}
                videoTitle="Test Video"
              />
            ) : (
              <Img
                fluid={
                  solution.Solution_Image.localFiles[0].childImageSharp.fluid
                }
                className={`w-100 ${styles.solutionImage}`}
              />
            )}
          </Col>
          <Col
            className={`d-flex flex-column justify-content-between ${styles.solutionDetailCol}`}
          >
            <p className={`${styles.solutionDetailDescription}`}>
              {solution.Detail_Description}
            </p>

            <div
              className={`d-flex flex-column justify-content-center w-100 text-center align-items-center ${styles.data}`}
            >
              <div className={`${styles.dataValue}`}>{solution.Data_Value}</div>
              <div className={`${styles.dataSub}`}>{solution.Data_Sub}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    airtable(data: { Name: { eq: $name } }) {
      data {
        Name
        Detail_Title
        Video_Link
        Solution_Image {
          localFiles {
            childImageSharp {
              fluid(quality: 90, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        Detail_Description
        Data_Value
        Data_Sub
        Data_Sheet
        Success_Headline
        Code_Description
        Code {
          localFiles {
            childImageSharp {
              fluid(quality: 90, maxWidth: 512) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      recordId
    }
  }
`

export default Solution
